import React from 'react';
import {Grid} from '@mui/material';
import InputWithDividers from '../../Input/InputWithDividers/InputWithDividers';
import ListWithHeader from '../../List/ListWithHeader';
import PropTypes from 'prop-types';

const VaccinationSearchForm = ({
  vaccinationNumber,
  error,
  onVaccinationNumberChange,
}) => {
  return (
    <>
      <Grid item width={485}>
        <InputWithDividers
          value={vaccinationNumber}
          id={'vaccinationNumber'}
          onChange={onVaccinationNumberChange}
          error={error.required || error.invalid}
        />
      </Grid>
      {error.invalid && (
        <ListWithHeader
          isErrorText
          header={'Nie znaleziono numeru identyfikacyjnego.'}
          items={[
            'Spróbuj ponownie wpisać numer,',
            'Skontaktuj się bezpośrednio z gabinetem weterynaryjnym, którego numer widnieje na identyfikatorze psa',
          ]}
        />
      )}
    </>
  );
};

export default VaccinationSearchForm;

VaccinationSearchForm.propTypes = {
  error: PropTypes.object.isRequired,
  onVaccinationNumberChange: PropTypes.func.isRequired,
  vaccinationNumber: PropTypes.string.isRequired,
};
