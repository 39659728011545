import React from 'react';

import {pawImage} from '../../../../assets';

import styles from './Security.module.css';

const Security = () => {
  return (
    <div className={styles.sectionColumn}>
      <div className={styles.footerHeaderRow}>
        <div>
          <img src={pawImage} alt="" width={'134px'} className={styles.image} />
        </div>
        <div className={styles.footerHeaderColumn}>
          <div className={styles.footerHeader}>Bezpieczeństwo</div>
          <div className={styles.footerHeader2}>
            INFORMACJE ZAWARTE NA IDENTYFIKATORZE POMOGĄ ZAGUBIONEMU ZWIERZĘCIU{' '}
            <br /> WRÓCIĆ DO DOMU.
          </div>
        </div>
      </div>
      <div className={styles.footerText}>
        <div>
          Deklaracja i rok szczepienia zwiększają nasze bezpieczeństwo - w
          sytuacji krytycznej (np. pogryzienie) możemy zachować spokój.
        </div>
        <div className={styles.footerBoldItalicText}>
          Przywieszona Rabieska oznacza, że właściciel dba o swojego
          podopiecznego - regularnie odwiedzając lekarza weterynarii.
        </div>
      </div>
    </div>
  );
};

export default Security;
