import {EMAIL_REGEX} from '../const';

export const validateRegisterForm = ({email, password, privacyPolicy}) => {
  const newErrors = {};

  if (!email.match(EMAIL_REGEX)) {
    newErrors.email = 'Niepoprawny adres email';
  }

  if (password.length < 8) {
    newErrors.password = 'Niepoprawne hasło';
  }

  if (!privacyPolicy) {
    newErrors.privacyPolicy = 'Polityka prywatności jest wymagana';
  }
  return newErrors;
};
