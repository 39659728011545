import React from 'react';
import InputWithLabel from '../InputWithLabel/InputWithLabel';
import PropTypes from 'prop-types';

const InputWithDividers = ({value, onChange, ...props}) => {
  const SEPARATOR = ' - ';

  const formatValue = value => {
    let formattedValue = value.replace(/[^\d]/g, '');

    if (
      longerThanAndSeparatorOccurrencesCount(formattedValue, SEPARATOR, 2, 0)
    ) {
      formattedValue =
        formattedValue.slice(0, 2) + SEPARATOR + formattedValue.slice(2);
    } else if (
      shorterOrEqualThanAndSeparatorOccurrencesCount(
        formattedValue,
        SEPARATOR,
        5,
        1,
      )
    ) {
      formattedValue = formattedValue.slice(0, 2) + formattedValue.slice(5);
    }

    if (
      longerThanAndSeparatorOccurrencesCount(formattedValue, SEPARATOR, 9, 1)
    ) {
      formattedValue =
        formattedValue.slice(0, 9) + SEPARATOR + formattedValue.slice(9);
    } else if (
      shorterOrEqualThanAndSeparatorOccurrencesCount(
        formattedValue,
        SEPARATOR,
        12,
        2,
      )
    ) {
      formattedValue = formattedValue.slice(0, 9) + formattedValue.slice(12);
    }
    return formattedValue;
  };

  const longerThanAndSeparatorOccurrencesCount = (
    value,
    separator,
    longerThanValue,
    maxSeparatorOccurrences,
  ) =>
    value.length > longerThanValue &&
    countOccurrences(value, separator) === maxSeparatorOccurrences;

  const countOccurrences = (str, value) => {
    const regExp = new RegExp(value, 'gi');
    return (str.match(regExp) || []).length;
  };

  const shorterOrEqualThanAndSeparatorOccurrencesCount = (
    value,
    separator,
    longerThanValue,
    maxSeparatorOccurrences,
  ) =>
    value.length <= longerThanValue &&
    countOccurrences(value, separator) === maxSeparatorOccurrences;

  return (
    <InputWithLabel
      label={'10-cyfrowy numer identyfikacyjny na Rabiesce'}
      value={formatValue(value)}
      onChange={e => onChange(e?.target?.value?.replaceAll(SEPARATOR, ''))}
      placeholder={'xx - xxxx - xxxx'}
      inputProps={{maxLength: 16}}
      required
      {...props}
    />
  );
};

export default InputWithDividers;

InputWithDividers.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
