import React from 'react';
import {Grid} from '@mui/material';
import classNames from 'classnames';
import styles from './ListWithHeader.module.css';
import PropTypes from 'prop-types';

const ListWithHeader = ({header, items, isErrorText}) => (
  <Grid
    item
    width={485}
    mt={1}
    className={classNames(styles.normalText, {
      [styles.redText]: isErrorText,
      [styles.listText]: !isErrorText,
    })}>
    <h6 className={isErrorText ? styles.redText : styles.normalHeader}>
      {header}
    </h6>
    <ul>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </Grid>
);

export default ListWithHeader;

ListWithHeader.propTypes = {
  header: PropTypes.string.isRequired,
  isErrorText: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};
