import React, {useState} from 'react';
import Header from '../../../components/Header/Header';
import {vaccinationIdImage} from '../../../assets';
import {Grid} from '@mui/material';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import HTTPService from '../../../service/HTTPService/HTTPService';
import {logNetworkError} from '../../../utils/logNetworkError';
import VaccinationDetailsForm from '../../../components/VaccinationForm/VaccinationDetailsForm/VaccinationDetailsForm';
import VaccinationSearchForm from '../../../components/VaccinationForm/VaccinationSearchForm/VaccinationSearchForm';
import {useNavigate} from 'react-router-dom';
import {SUCCESSFUL_REPORT} from '../../../const/routes';
import Loader from '../../../components/Loader/Loader';
import {isValidPhoneNumber} from 'libphonenumber-js';

const RegisterFindingPetPage = () => {
  const [vaccinationNumber, setVaccinationNumber] = useState('');
  const [error, setError] = useState({});
  const [phoneError, setPhoneError] = useState('');
  const [vaccination, setVaccination] = useState(null);
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const searchVaccination = () => {
    setError({});

    if (vaccinationNumber.length < 10) {
      setError({required: true});
    } else {
      setLoading(true);

      HTTPService.getVaccinationByOfficeNumber(vaccinationNumber)
        .then(({data}) => setVaccination(data))
        .catch(error => {
          logNetworkError(error);
          setError({invalid: true});
        })
        .finally(() => setLoading(false));
    }
  };

  const handleSubmit = () => {
    if (vaccination) {
      reportVaccination();
    } else {
      searchVaccination();
    }
  };

  const reportVaccination = () => {
    setLoading(true);
    if (phone.trim().length <= 4 || isValidPhoneNumber(phone.trim())) {
      HTTPService.reportVaccinationByOfficeNumber(
        vaccinationNumber,
        phone.trim().length > 4 ? phone.trim() : null,
      )
        .then(() => navigate(SUCCESSFUL_REPORT))
        .catch(err => {
          logNetworkError(err);
          setLoading(false);
          setError({report: true});
        });
    } else {
      setPhoneError('Powyższy numer telefonu jest nieprawidłowy.');
      setLoading(false);
    }
  };

  return (
    <Grid container alignItems={'center'} justifyContent={'center'}>
      {loading && <Loader />}
      <Grid container width={650} direction={'column'}>
        <Header
          text={
            vaccination
              ? 'Udało się znaleźć psa w bazie danych'
              : 'POINFORMAUJ O ZNALEZIONYM PSIE'
          }
          description={
            vaccination
              ? 'Udało się znaleźć psa w naszej bazie. Teraz wystarczy, ' +
                'że klikniesz przycisk poniżej.'
              : 'Na identyfikatorze przyczepionym do obroży, znajduje się 10 cyforwy numer, wpisz go poniżej.'
          }>
          <img src={vaccinationIdImage} alt={''} />
        </Header>
        {vaccination ? (
          <VaccinationDetailsForm
            vaccination={vaccination}
            phone={phone}
            setPhone={setPhone}
            error={error}
            setPhoneError={setPhoneError}
            phoneError={phoneError}
          />
        ) : (
          <VaccinationSearchForm
            vaccinationNumber={vaccinationNumber}
            onVaccinationNumberChange={setVaccinationNumber}
            error={error}
          />
        )}

        <Grid item mt={4}>
          <PrimaryButton onClick={handleSubmit}>
            Zgłos znalezienie
          </PrimaryButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegisterFindingPetPage;
