import React from 'react';
import {useNavigate} from 'react-router-dom';
import {HOME_PAGE} from '../../const/routes';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import styles from './SuccessfulAddedVacination.module.css';

const SuccessfulAddedVacination = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <h1>UDAŁO SIĘ DODAĆ SZCZEPIENIE</h1>
      <p className={styles.text1}>
        Twoje szczepienie zostało dodane, w każdej chwili możesz sprawdzić
        podane informacje.
      </p>
      <PrimaryButton onClick={() => navigate(HOME_PAGE)}>Gotowe</PrimaryButton>
    </div>
  );
};

export default SuccessfulAddedVacination;
