import React from 'react';
import {Grid} from "@mui/material";
import styles from "./SuccessfulRegistrationPage.module.css";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import {useNavigate} from "react-router-dom";
import {HOME_PAGE} from "../../../const/routes";

const SuccessfulRegistrationPage = () => {
    const navigate = useNavigate();

    return (
        <Grid container alignItems={'center'} justifyContent={'center'}>
            <Grid container width={441} direction={'column'} gap={4} mt={5} mb={5}>
                <Grid item>
                    <h3 className={styles.header}>UDAŁO SIĘ ZAŁOŻYĆ KONTO!</h3>
                </Grid>
                <Grid item>
                    <p className={styles.text}>
                        Od teraz możesz bezpiecznie zarejestrować szczepienie swojego psa oraz korzystać z naszej bazy
                        zwierząt. W przypadku znalezienia przez Ciebie psa pozwala ona szybko sprawdzić status
                        szczepienia oraz poinformować właściciela o zgubie.
                    </p>
                </Grid>
                <Grid item>
                    <PrimaryButton onClick={() => navigate(HOME_PAGE)}>
                        Gotowe
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SuccessfulRegistrationPage;