import React from 'react';
import Button from '@mui/material/Button';

import classNames from 'classnames';
import styles from './SecondaryButton.module.css';

const SecondaryButton = ({children, className, ...props}) => {
  return (
    <Button className={classNames(styles.customButton, className)} {...props}>
      {children}
    </Button>
  );
};

export default SecondaryButton;
