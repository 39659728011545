import React from 'react';
import {useNavigate} from 'react-router-dom';
import {
  RegisterVaccinationTileBackgroundImage,
  ReportFindingPetTileBackgroundImage,
} from '../../assets';
import {
  ADD_VACCINATION_PAGE,
  REGISTER_FINDING_PET_PAGE,
} from '../../const/routes';

import SecondaryButton from '../SecondaryButton/SecondaryButton';
import styles from './Tiles.module.css';

const Tiles = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.section}>
      <div
        style={{
          backgroundImage:
            'url(' + RegisterVaccinationTileBackgroundImage + ')',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '300px',
          margin: '15px',
          borderRadius: '20px',
          width: '570px',
          display: 'flex',
        }}>
        <div className={styles.tileContainer}>
          <div>
            <div className={styles.tileHeader}>Zarejestruj szczepienie</div>
            <div className={styles.tileText}>
              Dzięki Rabiesce możesz sprawdzić dane na temat szczepienia swojego
              psa. Wystarczy wpisać 10-cyfrowy kod z identyfikatora.
            </div>
          </div>
          <SecondaryButton
            onClick={() => navigate(ADD_VACCINATION_PAGE)}
            className={styles.tileButton}>
            Zarejestruj szczepienie
          </SecondaryButton>
        </div>
      </div>
      <div
        style={{
          backgroundImage: 'url(' + ReportFindingPetTileBackgroundImage + ')',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '300px',
          margin: '15px',
          borderRadius: '20px',
          width: '570px',
          display: 'flex',
        }}>
        <div className={styles.tileContainer}>
          <div>
            <div className={styles.tileHeader}>ZGŁOŚ ZNALEZIENIE</div>
            <div className={styles.tileText}>
              Znalazłeś psa z Rabieską? Dzięki innowacyjnej bazie, szybko
              poinformujemy właściciela.
            </div>
          </div>
          <SecondaryButton
            onClick={() => navigate(REGISTER_FINDING_PET_PAGE)}
            className={styles.tileButton}>
            Zgłoś znalezienie
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default Tiles;
