import React, {useState} from 'react';
import DatePicker from '../../../components/DatePicker/DatePicker';
import InputWithLabel from '../../../components/Input/InputWithLabel/InputWithLabel';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';

import styles from './AddVaccinationPage.module.css';
import {CircularProgress} from '@mui/material';
import SuccessfulAddedVacination from '../../../components/SuccessfulAddedVacination/SuccessfulAddedVacination';
import {logNetworkError} from '../../../utils/logNetworkError';
import HTTPService from '../../../service/HTTPService/HTTPService';
import PhoneInput from '../../../components/PhoneInput/PhoneInput';

import {isValidPhoneNumber} from 'libphonenumber-js';
import InputWithDividers from '../../../components/Input/InputWithDividers/InputWithDividers';
import {backendErrors} from '../../../const/errors';

const AddVaccinationPage = () => {
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const [clinicNameInput, setClinicNameInput] = useState('');
  const [clinicAddressInput, setClinicAddressInput] = useState('');
  const [clinicPhoneInput, setClinicPhoneInput] = useState('');
  const [ownerPhoneInput, setOwnerPhoneInput] = useState('');

  const [idNumberInput, setIdNumberInput] = useState('');
  const [idNumberError, setIdNumberError] = useState('');

  const [vaccinationDate, setVaccinationDate] = useState(null);
  const [vaccinationDateError, setVaccinationDateError] = useState('');

  const [clinicPhoneError, setClinicPhoneError] = useState('');
  const [ownerPhoneError, setOwnerPhoneError] = useState('');

  const [isVacinationAdded, setIsVacinationAdded] = useState(false);

  const submitHandler = e => {
    e.preventDefault();

    const idNumber = idNumberInput.replace(/[^\d]/g, '');
    if (idNumber.length !== 10) {
      setIdNumberError('Powyższe pole jest wymagane.');
    }
    if (!vaccinationDate) {
      setVaccinationDateError('Powyższe pole jest wymagane.');
    }
    if (
      clinicPhoneInput.trim().length > 4 &&
      !isValidPhoneNumber(clinicPhoneInput.trim())
    ) {
      setClinicPhoneError('Powyższy numer telefonu jest nieprawidłowy.');
    }
    if (
      ownerPhoneInput.trim().length > 4 &&
      !isValidPhoneNumber(ownerPhoneInput.trim())
    ) {
      setOwnerPhoneError('Powyższy numer telefonu jest nieprawidłowy.');
    }

    if (
      idNumber.length === 10 &&
      vaccinationDate &&
      (clinicPhoneInput.trim().length <= 4 ||
        isValidPhoneNumber(clinicPhoneInput.trim())) &&
      (ownerPhoneInput.trim().length <= 4 ||
        isValidPhoneNumber(ownerPhoneInput.trim()))
    ) {
      setIsFetching(true);
      const postBody = {
        vaccinationType: 'RABIES',
        vaccinationDate: vaccinationDate.toISOString(),
        office: {
          name: clinicNameInput.trim(),
          address: clinicAddressInput.trim(),
          phoneNumber: isValidPhoneNumber(clinicPhoneInput.trim())
            ? clinicPhoneInput.trim()
            : null,
        },
        vaccinationOfficeNumber: idNumber,
        ownerPhoneNumber: isValidPhoneNumber(ownerPhoneInput.trim())
          ? ownerPhoneInput.trim()
          : null,
      };
      HTTPService.addVaccination(postBody)
        .then(() => setIsVacinationAdded(true))
        .catch(err => {
          logNetworkError(err);
          setIsFetching(false);

          if (err?.response?.data?.message === 'Vaccination Exists') {
            setIdNumberError(backendErrors[err.response.data.message]);
          }
        });
    }
  };

  return (
    <div className={styles.container}>
      {isVacinationAdded ? (
        <SuccessfulAddedVacination />
      ) : (
        <form onSubmit={submitHandler} className={styles.formContainer}>
          <h1>DODAJ SZCZEPIENIE</h1>
          <p className={styles.text1}>
            Uzupełnij dane związane ze szczepieniem psa przeciwko wściekliźnie.
          </p>
          <DatePicker
            error={vaccinationDateError !== ''}
            label={'Data szczepienia'}
            inputFormat="DD/MM/YYYY"
            onChange={setVaccinationDate}
            id="vaccinationId"
            value={vaccinationDate}
            required
            onFocus={() => {
              setVaccinationDateError('');
              setError('');
            }}
          />
          {vaccinationDateError && (
            <div className={styles.errorText}>{vaccinationDateError}</div>
          )}

          <InputWithDividers
            error={idNumberError !== ''}
            onChange={setIdNumberInput}
            id={'idNumber'}
            value={idNumberInput}
            onFocus={() => {
              setIdNumberError('');
              setError('');
            }}
          />
          {idNumberError && (
            <div className={styles.errorText}>{idNumberError}</div>
          )}
          <h2>Dane kontaktowe do gabinetu</h2>
          <InputWithLabel
            label={'Nazwa gabinetu, w którym odbyło się szczepienie'}
            onChange={e => setClinicNameInput(e.target.value)}
            id={'clinicName'}
            value={clinicNameInput}
            type={'text'}
            placeholder="np. Rikola"
            onFocus={() => setError('')}
          />
          <InputWithLabel
            label={'Adres Gabinetu'}
            onChange={e => setClinicAddressInput(e.target.value)}
            id={'clinicAddress'}
            value={clinicAddressInput}
            type={'text'}
            placeholder="np. Wrocławska 18, Wrocław"
            onFocus={() => setError('')}
          />
          <PhoneInput
            label={'Telefon do gabinetu'}
            onChange={setClinicPhoneInput}
            id={'clinicPhone'}
            value={clinicPhoneInput}
            error={clinicPhoneError !== ''}
            onFocus={() => {
              setError('');
              setClinicPhoneError('');
            }}
          />
          {clinicPhoneError && (
            <div className={styles.errorText}>{clinicPhoneError}</div>
          )}
          <h2>Dane kontaktowe do własciciela</h2>
          <PhoneInput
            label={'Telefon do właściciela'}
            onChange={setOwnerPhoneInput}
            id={'ownerPhone'}
            value={ownerPhoneInput}
            error={ownerPhoneError !== ''}
            onFocus={() => {
              setError('');
              setOwnerPhoneError('');
            }}
          />
          {ownerPhoneError && (
            <div className={styles.errorText}>{ownerPhoneError}</div>
          )}
          {error && <div className={styles.errorText}>{error}</div>}
          <div className={styles.submitButtonRow}>
            <PrimaryButton className={styles.submitButton} type="submit">
              Dodaj szczepienie
            </PrimaryButton>
            {isFetching && <CircularProgress />}
          </div>
        </form>
      )}
    </div>
  );
};

export default AddVaccinationPage;
