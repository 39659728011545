import {initializeApp} from 'firebase/app';

const config = {
  develop: {
    apiKey: 'AIzaSyDSaBg3aUFxctog0K2ijfabAIqVP-UogD8',
    authDomain: 'rabieski-dev.firebaseapp.com',
    projectId: 'rabieski-dev',
    storageBucket: 'rabieski-dev.appspot.com',
    messagingSenderId: '35729713396',
    appId: '1:35729713396:web:b7825c8168a23a6346d6a1',
    measurementId: 'G-W7C29GLMCH',
  },
  uat: {
    apiKey: 'AIzaSyCRmKydPdxuyFQ61zBlUHIGcV_3pb8FHk8',
    authDomain: 'rabieski-uat.firebaseapp.com',
    projectId: 'rabieski-uat',
    storageBucket: 'rabieski-uat.appspot.com',
    messagingSenderId: '229023179225',
    appId: '1:229023179225:web:aca7268fb444376cc639c8',
    measurementId: 'G-9PQMT31Q96',
  },
  production: {
    apiKey: 'AIzaSyBu70vatub5o5rphabP_QlTJobzYexeirc',
    authDomain: 'rabieski-prod.firebaseapp.com',
    projectId: 'rabieski-prod',
    storageBucket: 'rabieski-prod.appspot.com',
    messagingSenderId: '41390702326',
    appId: '1:41390702326:web:4ee3dbec9bbdf06279b6d7',
    measurementId: 'G-NY0E65Q0NP',
  },
};
//pick config
initializeApp(config.production);
