import React from 'react';
import {Link} from 'react-router-dom';
import {HOME_PAGE} from '../../../const/routes';
import styles from './NotFoundPage.module.css';

const NotFoundPage = () => {
  return (
    <div className={styles.page}>
      <div>404</div>
      <div>Page not found</div>
      <Link to={HOME_PAGE}>Go to home page</Link>
    </div>
  );
};

export default NotFoundPage;
