import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {REGISTER_PAGE} from '../../const/routes';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import styles from './LoginForm.module.css';

import {firebaseLoginErrors} from '../../const/errors';
import {EMAIL_REGEX} from '../../const';
import InputWithLabel from '../Input/InputWithLabel/InputWithLabel';
import {CircularProgress} from '@mui/material';

const LoginForm = () => {
  const navigate = useNavigate();

  const auth = getAuth();

  const [loginInput, setLoginInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const loginHandler = e => {
    e.preventDefault();

    if (!loginInput) {
      setError('Pole Login nie może być puste');
    } else if (!loginInput.toLowerCase().match(EMAIL_REGEX)) {
      setError('Wprowadzony adres e-mail jest niepoprawnie sformatowany.');
    } else if (!passwordInput) {
      setError('Pole Hasło nie może być puste');
    } else {
      setIsFetching(true);
      signInWithEmailAndPassword(auth, loginInput, passwordInput).catch(err => {
        setError(firebaseLoginErrors[err.code] || 'Wystąpił błąd.');
        setIsFetching(false);
      });
    }
  };
  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginFormHeader}>
        <div className={styles.loginFormHeaderText1}>LOGOWANIE</div>
        <div className={styles.loginFormHeaderRightContainer}>
          <div className={styles.loginFormHeaderText2}>Nie masz konta?</div>
          <div
            onClick={() => navigate(REGISTER_PAGE)}
            className={styles.loginFormHeaderLink}>
            Zarejestruj się
          </div>
        </div>
      </div>
      <form className={styles.loginForm} onSubmit={loginHandler}>
        <InputWithLabel
          label={'Login'}
          onChange={e => setLoginInput(e.target.value)}
          id={'email'}
          value={loginInput}
          required
          type={'email'}
          placeholder="np. example@gmail.com"
          onFocus={() => setError('')}
        />

        <InputWithLabel
          label={'Hasło'}
          onChange={e => setPasswordInput(e.target.value)}
          id={'password'}
          value={passwordInput}
          required
          password
          placeholder="Wpisz hasło"
          onFocus={() => setError('')}
        />

        {error && <div style={{color: 'red', marginTop: '12px'}}>{error}</div>}
        <div
          style={{
            alignSelf: 'flex-start',
            marginTop: '12px',
            marginBottom: '32px',
          }}>
          {/* <div className={styles.loginFormHeaderLink}>Nie pamiętam hasła</div> */}
        </div>

        <div className={styles.submitButtonRow}>
          <PrimaryButton className={styles.submitButton} type="submit">
            Zaloguj
          </PrimaryButton>
          {isFetching && <CircularProgress />}
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
