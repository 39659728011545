import {useSelector} from 'react-redux';
import {Navigate, Outlet} from 'react-router-dom';
import {LOGIN_PAGE} from '../const/routes';

const RequireAuth = () => {
  const auth = useSelector(state => state.auth.userData);
  if (!auth) {
    return <Navigate to={LOGIN_PAGE} />;
  }
  return <Outlet />;
};
export default RequireAuth;
