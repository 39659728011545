import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import styles from './VaccinationsList.module.css';
import {CircularProgress} from '@mui/material';
import HTTPService from '../../service/HTTPService/HTTPService';
import dayjs from 'dayjs';
import {logNetworkError} from '../../utils/logNetworkError';

const VaccinationsList = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [vaccinations, setVaccinations] = useState([]);

  const fetchAllVaccinations = () => {
    HTTPService.getVaccinations(Number.MAX_SAFE_INTEGER, 0)
      .then(res => {
        const results = res.data.results;
        results.sort(
          (a, b) => new Date(b.vaccinationDate) - new Date(a.vaccinationDate),
        );
        setVaccinations(results);
        setIsFetching(false);
      })
      .catch(err => logNetworkError(err));
  };

  useEffect(() => {
    fetchAllVaccinations();
  }, []);

  return (
    <div className={styles.vaccinationsListColumn}>
      <div className={styles.listHeader}>
        Lista szczepień przeciw wściekliźnie
      </div>
      <div className={styles.listText}>
        Szczepienia zarejestrowane przez Rabieski. Kontroluj terminy, wszystko z
        jednym miejscu.
      </div>
      <div className={styles.listText}>
        Jeśli chcesz edytować dane wprowadzone przy rejestracji szczepienia
        prosimy o kontakt z Administratorem na biuro@rabieski.pl
      </div>
      {isFetching ? (
        <CircularProgress className={styles.loader} />
      ) : vaccinations.length !== 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 400,
            marginBottom: '80px',
            marginTop: '30px',
            backgroundColor: '#F8F8F8',
          }}>
          <Table sx={{minWidth: 650}} aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableHeaderCell}>
                  Identyfikator
                </TableCell>
                <TableCell className={styles.tableHeaderCell}>
                  Data szczepienia
                </TableCell>
                <TableCell className={styles.tableHeaderCell}>
                  Gabinet
                </TableCell>
                <TableCell className={styles.tableHeaderCell}>
                  Telefon
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vaccinations?.map(vaccination => (
                <TableRow
                  key={vaccination.id}
                  sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={styles.tableCellBold}>
                    {`${vaccination.vaccinationOfficeNumber.substr(
                      0,
                      2,
                    )}-${vaccination.vaccinationOfficeNumber.substr(
                      2,
                      4,
                    )}-${vaccination.vaccinationOfficeNumber.substr(6)}`}
                  </TableCell>
                  <TableCell className={styles.tableCellBold}>
                    {dayjs(vaccination.vaccinationDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {vaccination.office?.name}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {vaccination.office?.phoneNumber}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Paper className={styles.noVaccinations}>
          Brak dodanych szczepień.
        </Paper>
      )}
    </div>
  );
};

export default VaccinationsList;
