import React from 'react';
import {Grid} from '@mui/material';
import styles from './VaccinationDetailsForm.module.css';
import ListWithHeader from '../../List/ListWithHeader';
import PropTypes from 'prop-types';
import PhoneInput from '../../PhoneInput/PhoneInput';

const VaccinationDetailsForm = ({
  vaccination,
  phone,
  setPhone,
  error,
  phoneError,
  setPhoneError,
}) => {
  return (
    <>
      <Grid
        item
        className={styles.greyBackground}
        container
        justifyContent={'space-around'}
        alignItems={'center'}>
        <Grid item>
          <h4 className={styles.columnHeader}>Identyfikator</h4>
          <span className={styles.columnText}>
            {vaccination.vaccinationOfficeNumber}
          </span>
        </Grid>
        <Grid item>
          <h4 className={styles.columnHeader}>Data szczepienia</h4>
          <span className={styles.columnText}>
            {new Date(vaccination.vaccinationDate).toLocaleDateString('en-GB')}
          </span>
        </Grid>
      </Grid>
      <Grid item>
        <ListWithHeader
          header={'Dodaj swój numer telefonu'}
          items={[
            'Aby właściciel mógł się z Tobą skontaktować, podaj swój numer telefonu.',
            'Jeśli nie chcesz udostępniać właścicielowi swojego numeru w celu kontaktowym, to możesz bezpośrednio skontaktować się z gabinetem weterynaryjnym.',
          ]}
        />
      </Grid>
      <Grid item width={485}>
        <PhoneInput
          label={'Telefon'}
          onChange={setPhone}
          id={'phone'}
          value={phone}
          error={phoneError !== ''}
          onFocus={() => {
            setPhoneError('');
          }}
        />
        {phoneError && <div className={styles.errorText}>{phoneError}</div>}
      </Grid>
      {error.report && (
        <Grid item className={styles.error} mt={3}>
          Wystąpił błąd z wysłaniem zgłoszenia. Spróbuj ponownie później lub
          zgłoś to administracji.
        </Grid>
      )}
    </>
  );
};

export default VaccinationDetailsForm;

VaccinationDetailsForm.propTypes = {
  phone: PropTypes.string.isRequired,
  error: PropTypes.object.isRequired,
  setPhone: PropTypes.func.isRequired,
  vaccination: PropTypes.object.isRequired,
};
