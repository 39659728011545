import React from 'react';
import {Grid} from '@mui/material';
import {ReportFindingPetTileBackgroundImage} from '../../../assets';
import styles from './SuccessfulReportPage.module.css';
import SecondaryButton from '../../../components/SecondaryButton/SecondaryButton';
import {useNavigate} from 'react-router-dom';
import {HOME_PAGE} from '../../../const/routes';

const SuccessfulReportPage = () => {
  const navigate = useNavigate();

  const navigateToMainPage = () => navigate(HOME_PAGE);

  return (
    <Grid container alignItems={'center'} justifyContent={'center'}>
      <Grid
        container
        width={580}
        height={468}
        direction={'column'}
        padding={7}
        mt={8}
        style={{
          backgroundImage: 'url(' + ReportFindingPetTileBackgroundImage + ')',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderRadius: '20px',
        }}>
        <Grid item className={styles.text}>
          <h4 className={styles.header}>poinformowaliśmy właściciela psa</h4>
        </Grid>
        <Grid item className={styles.text} mt={4}>
          Poczekaj, aż właściciel skontaktuje się z Tobą za pomocą podanego
          wcześniej numeru telefonu.
        </Grid>
        <Grid item className={styles.text} mt={4}>
          Dziękujemy Ci za znalezienie oraz za opiekę nad psem.
        </Grid>
        <Grid item mt={8}>
          <SecondaryButton onClick={navigateToMainPage}>Gotowe</SecondaryButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SuccessfulReportPage;
