import './service/firebase';
import React from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import LoginPage from './pages/LoginStack/LoginPage';
import HomePage from './pages/HomeStack/HomePage/HomePage';
import Layout from './components/Layout/Layout';
import RegisterPage from './pages/RegisterStack/RegisterPage/RegisterPage';
import RequireAuth from './hoc/RequireAuth';
import NotFoundPage from './pages/HomeStack/NotFoundPage/NotFoundPage';
import AddVaccinationPage from './pages/HomeStack/AddVaccinationPage/AddVaccinationPage';
import RegisterFindingPetPage from './pages/HomeStack/RegisterFindingPetPage/RegisterFindingPetPage';
import {
  ADD_VACCINATION_PAGE,
  HOME_PAGE,
  LOGIN_PAGE,
  REGISTER_FINDING_PET_PAGE,
  REGISTER_PAGE,
  SUCCESSFUL_REGISTRATION_PAGE,
  SUCCESSFUL_REPORT,
} from './const/routes';
import SuccessfulRegistrationPage from './pages/RegisterStack/SuccessfulRegistrationPage/SuccessfulRegistrationPage';
import SuccessfulReportPage from './pages/HomeStack/SuccessfulReportPage/SuccessfulReportPage';
import withAuthentication from './hoc/withAuthentication/withAuthentication';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path={REGISTER_PAGE} element={<RegisterPage />} />
          <Route
            path={SUCCESSFUL_REGISTRATION_PAGE}
            element={<SuccessfulRegistrationPage />}
          />
          <Route path={LOGIN_PAGE} element={<LoginPage />} />
          <Route path={HOME_PAGE} element={<HomePage />} />
          <Route
            path={REGISTER_FINDING_PET_PAGE}
            element={<RegisterFindingPetPage />}
          />
          <Route path={SUCCESSFUL_REPORT} element={<SuccessfulReportPage />} />
          <Route element={<RequireAuth />}>
            <Route
              path={ADD_VACCINATION_PAGE}
              element={<AddVaccinationPage />}
            />
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default withAuthentication(App);
