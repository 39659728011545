import React from 'react';
import {Grid} from '@mui/material';
import styles from './Header.module.css';

const Header = ({text, description, children}) => {
  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      maxWidth
      mt={6}>
      <Grid item xs={7} container direction={'column'}>
        <h3 className={styles.header}>{text}</h3>
        {description && <p className={styles.description}>{description}</p>}
      </Grid>
      <Grid
        item
        xs={5}
        display={'flex'}
        alignItems={'center'}
        gap={1}
        justifyContent={'center'}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Header;
