import React from 'react';

import {documentImage} from '../../../../assets';

import styles from './PetsIdentification.module.css';

const PetsIdentification = () => {
  return (
    <div className={styles.sectionColumn}>
      <div className={styles.footerHeaderRow}>
        <div>
          <img
            src={documentImage}
            alt=""
            width={'134px'}
            className={styles.image}
          />
        </div>
        <div className={styles.footerHeaderColumn}>
          <div className={styles.footerHeader}>Identyfikacja zwierząt</div>
        </div>
      </div>
      <div className={styles.footerText}>
        <div>
          Numer szczepienia pozwala na dotarcie do właściciela dzięki
          prowadzonej ewidencji przez lekarza weterynarii. Dodatkowa rejestracja
          w bazie Rabiesek pozwala bezpośrednio dotrzeć do właściciela.
        </div>
        <div>
          Dzięki Rabieskom zwierzęta nie pozostają anonimowe i mogą bezpiecznie
          wrócić do domu.
        </div>
      </div>
    </div>
  );
};

export default PetsIdentification;
