import React from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDayjs';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import styles from './DatePicker.module.css';

const DatePicker = ({
  label,
  value,
  onChange,
  id,
  required,
  helperText,
  onFocus,
  error,
  ...props
}) => {
  return (
    <FormControl className={styles.container} onFocus={onFocus}>
      <InputLabel shrink htmlFor={id} className={styles.label}>
        <Grid justifyContent={'space-between'} container>
          <Grid item>{label}</Grid>
          {!required && (
            <Grid item className={styles.softText}>
              Nieobowiązkowe
            </Grid>
          )}
        </Grid>
      </InputLabel>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DesktopDatePicker
          value={value}
          onChange={onChange}
          id={id}
          {...props}
          renderInput={params => (
            <TextField
              className={error ? styles.inputError : styles.input}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
      {helperText && (
        <FormHelperText className={styles.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default DatePicker;
