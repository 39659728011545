import React from 'react';
import {dogImage1, dogImage2, dogImage3, dogImage4} from '../../assets';
import styles from './FoundPetSteps.module.css';

const FoundPetSteps = () => {
  return (
    <div>
      <section>
        <div className={styles.sectionRow}>
          <h1>CO ZROBIĆ GDY ZNALAZŁEŚ ZWIERZĘ?</h1>
        </div>
      </section>

      <section>
        <div className={styles.section}>
          <div className={styles.sectionColumn}>
            <div>
              <img src={dogImage1} alt="" width={'134px'} />
            </div>
            <div className={styles.stepHeader}>Krok 1:</div>
            <p className={styles.stepHeader2}>
              Odczytaj dane z identyfikatora:
            </p>
            <ul className={styles.dashList}>
              <li>numer identyfikacyjny</li>
              <li>dane kontaktowe lecznicy weterynaryjnej</li>
            </ul>
          </div>
          <div className={styles.sectionColumn}>
            <div>
              <img
                src={dogImage2}
                alt=""
                width={'134px'}
                onMouseOver={e => (e.currentTarget.src = dogImage3)}
                onMouseOut={e => (e.currentTarget.src = dogImage2)}
              />
            </div>
            <div className={styles.stepHeader}>Krok 2:</div>
            <p className={styles.text}>
              Skontaktuj się z lekarzem weterynarii podając numer
              identyfikacyjny
            </p>
            <p className={styles.text}>LUB</p>
            <p className={styles.text}>
              Sprawdż 10 cyfrowy numer w bazie Rabiesek.
            </p>
          </div>
          <div className={styles.sectionColumn}>
            <div>
              <img src={dogImage4} alt="" width={'134px'} />
            </div>
            <div className={styles.stepHeader}>Krok 3:</div>
            <p className={styles.text}>
              Po skontaktowaniu się z właścicielem, zguba może bezpiecznie
              wrócić do domu.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FoundPetSteps;
