import {FormControl, FormHelperText, Grid, InputLabel} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import React from 'react';

import styles from './PhoneInput.module.css';

const PhoneInput = ({
  label,
  value,
  onChange,
  id,
  required,
  helperText,
  onFocus,
  ...props
}) => {
  return (
    <FormControl className={styles.container} onFocus={onFocus}>
      <InputLabel shrink htmlFor={id} className={styles.label}>
        <Grid justifyContent={'space-between'} container>
          <Grid item>{label}</Grid>
          {!required && (
            <Grid item className={styles.softText}>
              Nieobowiązkowe
            </Grid>
          )}
        </Grid>
      </InputLabel>
      <MuiPhoneNumber
        id={id}
        className={styles.input}
        defaultCountry={'pl'}
        value={value}
        onChange={onChange}
        variant="outlined"
        regions={'europe'}
        {...props}
      />
      {helperText && (
        <FormHelperText className={styles.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default PhoneInput;
