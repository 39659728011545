import React, {useState} from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import styles from './InputWithLabel.module.css';
import {VisibilityOff, VisibilityOn} from '../../../assets';

const InputWithLabel = ({
  label,
  value,
  onChange,
  id,
  required,
  helperText,
  password,
  ...props
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePassword = () => setPasswordVisible(prevState => !prevState);

  return (
    <FormControl className={styles.container}>
      <InputLabel shrink htmlFor={id} className={styles.label}>
        <Grid justifyContent={'space-between'} container>
          <Grid item>{label}</Grid>
          {!required && (
            <Grid item className={styles.softText}>
              Nieobowiązkowe
            </Grid>
          )}
        </Grid>
      </InputLabel>
      <TextField
        value={value}
        onChange={onChange}
        id={id}
        className={styles.input}
        type={password && !passwordVisible ? 'password' : props?.type || 'text'}
        {...props}
      />
      {password && (
        <InputAdornment position="end" className={styles.icon}>
          <IconButton
            onClick={togglePassword}
            aria-label="toggle password visibility">
            {!passwordVisible ? (
              <VisibilityOff fill="#575F6E" />
            ) : (
              <VisibilityOn fill="#575F6E" />
            )}
          </IconButton>
        </InputAdornment>
      )}
      {helperText && (
        <FormHelperText className={styles.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputWithLabel;

InputWithLabel.propTypes = {
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  password: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
};
