import React from 'react';
import {useSelector} from 'react-redux';
import AboutTag from '../../../components/AboutTag/AboutTag';
import FoundPetSteps from '../../../components/FoundPetSteps/FoundPetSteps';
import Tiles from '../../../components/Tiles/Tiles';
import VaccinationsList from '../../../components/VaccinationsList/VaccinationsList';
import styles from './HomePage.module.css';

const HomePage = () => {
  const userData = useSelector(state => state.auth.userData);
  return (
    <div>
      <section>
        <div className={styles.sectionRow}>
          <h1>Znalazłem psa / rejestracja Rabieski</h1>
        </div>
      </section>

      <section>
        <div className={styles.sectionColumn}>
          <Tiles />
          {userData && <VaccinationsList />}
        </div>
      </section>

      <FoundPetSteps />
      <AboutTag />
    </div>
  );
};

export default HomePage;
