import axios from 'axios';
import {getAuth} from 'firebase/auth';
import {
  ME,
  REPORT_VACCINATION,
  USER,
  VACCINATION_OFFICE_NUMBER,
  VACCINATION,
} from './endpoints';

const baseURL = 'https://api.panel.rabieski.pl';
const auth = getAuth();

class HTTPService {
  constructor() {
    this.axios = axios.create({baseURL});
    this.axios.interceptors.request.use(async config => {
      const token = await auth.currentUser?.getIdToken();
      const editedConfig = {...config};
      if (token) {
        editedConfig.headers = config.headers || {};
        editedConfig.headers.Authorization = `Bearer ${token}`;
      }
      return editedConfig;
    }, Promise.reject);
  }

  getUserData = () => this.axios.get(ME);

  createUser = ({email, password, name}) =>
    this.axios.post(USER, {name, email, password});

  addVaccination = ({
    vaccinationType,
    vaccinationDate,
    office,
    vaccinationOfficeNumber,
    ownerPhoneNumber,
  }) =>
    this.axios.post(VACCINATION, {
      vaccinationType,
      vaccinationDate,
      office,
      vaccinationOfficeNumber,
      ownerPhoneNumber,
    });

  getVaccinations = (size, page) => {
    return this.axios.get(VACCINATION, {params: {size: size, page: page}});
  };
  getVaccinationByOfficeNumber = officeNumber =>
    this.axios.get(VACCINATION_OFFICE_NUMBER(officeNumber));

  reportVaccinationByOfficeNumber = (
    vaccinationOfficeNumber,
    ownerPhoneNumber,
  ) =>
    this.axios.post(REPORT_VACCINATION, {
      vaccinationOfficeNumber,
      ownerPhoneNumber,
    });
}

export default new HTTPService();
