import React from 'react';

import {lightningImage} from '../../../../assets';

import styles from './InstantContact.module.css';

const InstantContact = () => {
  return (
    <div className={styles.sectionColumn}>
      <div className={styles.footerHeaderRow}>
        <div>
          <img
            src={lightningImage}
            alt=""
            width={'134px'}
            className={styles.image}
          />
        </div>
        <div className={styles.footerHeaderColumn}>
          <div className={styles.footerHeader}>Błyskawiczny kontakt</div>
          <div className={styles.footerHeader2}>
            INFORMACJE ODCZYTASZ BEZ SPECJALISTYCZNEGO CZYTNIKA.
          </div>
        </div>
      </div>

      <div className={styles.footerText}>
        Informacje widoczne są gołym okiem - dzięki temu możemy szybko
        skontaktować się z lekarzem weterynarii lub właścicielem.
      </div>
    </div>
  );
};

export default InstantContact;
