import React from 'react';

import styles from './Footer.module.css';
import {logoFooter} from '../../assets';
import Security from './components/Security/Security';
import InstantContact from './components/InstantContact/InstantContact';
import PetsIdentification from './components/PetsIdentification/PetsIdentification';

const Footer = () => {
  return (
    <div>
      <section>
        <div className={styles.section}>
          <Security />
          <InstantContact />
          <PetsIdentification />
        </div>
      </section>
      <section>
        <div className={styles.grayFooter}>
          <div className={styles.grayFooterRow}>
            <img
              src={logoFooter}
              alt="logo-footer"
              className={styles.logoFooter}
            />
            <div className={styles.grayFooterText}>
              ALGRAPH 2015 wszelkie prawa zastrzeżone.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
