import {getAuth, onAuthStateChanged} from 'firebase/auth';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import Loader from '../../components/Loader/Loader';
import {setUserData} from '../../store/slicer/auth';

import styles from './withAuthentication.module.css';

export default Component => props => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const auth = getAuth();

  onAuthStateChanged(auth, user => {
    if (user) {
      dispatch(setUserData({email: user.email}));
      isLoading && setIsLoading(false);
    } else {
      dispatch(setUserData(null));
      isLoading && setIsLoading(false);
    }
  });
  return isLoading ? (
    <div className={styles.loaderContainer}>
      <Loader />
    </div>
  ) : (
    <Component {...props} />
  );
};
