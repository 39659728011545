import React from 'react';
import {Outlet} from 'react-router-dom';
import styles from './Layout.module.css';
import {useSelector} from 'react-redux';
import Footer from '../Footer/Footer';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import {useNavigate} from 'react-router-dom';
import {rabieskiLogo} from '../../assets';
import {HOME_PAGE, LOGIN_PAGE, REGISTER_PAGE} from '../../const/routes';
import {getAuth, signOut} from 'firebase/auth';

const Layout = ({children, ...props}) => {
  const navigate = useNavigate();

  const auth = getAuth();

  const logoutHandler = () => {
    signOut(auth).catch(console.error);
  };
  const userData = useSelector(state => state.auth.userData);
  return (
    <div>
      <section>
        <div className={styles.header}>
          <a href="https://rabieski.pl" className={styles.logoContainer}>
            <img src={rabieskiLogo} alt="" />
          </a>

          <div className={styles.headerBar}>
            {userData ? (
              <>
                <div className={styles.userEmail}>{userData?.email}</div>
                <SecondaryButton
                  onClick={logoutHandler}
                  className={styles.headerButton}>
                  Wyloguj
                </SecondaryButton>
              </>
            ) : (
              <>
                <PrimaryButton
                  onClick={() => navigate(LOGIN_PAGE)}
                  className={styles.headerButton}>
                  Logowanie
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => navigate(REGISTER_PAGE)}
                  className={styles.headerButton}>
                  Rejestracja
                </PrimaryButton>
              </>
            )}
          </div>
        </div>
      </section>

      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
