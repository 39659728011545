import React, {useState} from 'react';
import styles from './RegisterPage.module.css';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import {LOGIN_PAGE, SUCCESSFUL_REGISTRATION_PAGE} from '../../../const/routes';
import InputWithLabel from '../../../components/Input/InputWithLabel/InputWithLabel';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import {validateRegisterForm} from '../../../utils/validationUtils';
import {POLICY_PRIVACY} from '../../../const';
import HTTPService from '../../../service/HTTPService/HTTPService';
import {logNetworkError} from '../../../utils/logNetworkError';
import Loader from '../../../components/Loader/Loader';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import Header from '../../../components/Header/Header';

const RegisterPage = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
    privacyPolicy: false,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const onFieldChange = e => {
    setForm(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const submitForm = () => {
    const errors = validateRegisterForm(form);
    if (!Object.values(errors).some(Boolean)) {
      setLoading(true);
      HTTPService.createUser(form)
        .then(() => signInWithEmailAndPassword(auth, form.email, form.password))
        .then(() => navigate(SUCCESSFUL_REGISTRATION_PAGE))
        .catch(err => {
          logNetworkError(err);
          setErrors({server: 'Podane dane są niepoprawne.'});
          setLoading(false);
        });
    } else {
      setErrors(errors);
    }
  };

  const togglePrivacyPolicy = () =>
    setForm(prevState => ({
      ...prevState,
      privacyPolicy: !prevState.privacyPolicy,
    }));

  return (
    <Grid container alignItems={'center'} justifyContent={'center'}>
      {loading && <Loader />}
      <Grid container width={485} direction={'column'}>
        <Header text={'Rejestracja konta'}>
          <p className={styles.softText}>Masz już konto?</p>
          <Link to={LOGIN_PAGE} className={styles.link}>
            Zaloguj się
          </Link>
        </Header>
        <Grid item>Uzupełnij dane potrzebne do rejestracji Twojego konta.</Grid>
        <Grid item>
          <InputWithLabel
            label={'Imię i nazwisko'}
            onChange={onFieldChange}
            id={'name'}
            value={form.name}
            placeholder={'np Mirek Warszawiak'}
          />
        </Grid>
        <Grid item>
          <InputWithLabel
            label={'Email'}
            onChange={onFieldChange}
            id={'email'}
            value={form.email}
            required
            error={errors.email}
            type={'email'}
            placeholder={'np mirek@gmail.com'}
          />
        </Grid>
        <Grid item>
          <InputWithLabel
            label={'Hasło'}
            onChange={onFieldChange}
            id={'password'}
            value={form.password}
            error={errors.password}
            required
            password
            helperText={'Minimum 8 znaków'}
            placeholder={'*****'}
          />
        </Grid>
        <Grid item marginTop={5}>
          <FormControl required error={errors.privacyPolicy}>
            {errors.privacyPolicy && <FormLabel>Pole wymagane</FormLabel>}
            <FormControlLabel
              className={styles.checkboxLabel}
              control={
                <Checkbox
                  checked={form.privacyPolicy}
                  onChange={togglePrivacyPolicy}
                />
              }
              label={
                <span className={styles.checkboxText}>
                  Wyrażam zgodę na przetwarzanie moich danych osobowych przez
                  ALGRAPH Aleksandra Kamińska z siedzibą przy ul. Wieluńskiej 29
                  w Rudniku w celach realizacji podstawowych założeń platformy
                  Rabieski i rejestru szczepień psów. Aby uzyskać szczegółowe
                  informacje, zapoznaj się z{' '}
                  <a
                    className={styles.checkboxLink}
                    href={POLICY_PRIVACY}
                    target={'_blank'}
                    rel="noopener noreferrer">
                    Polityką prywatności
                  </a>
                  .
                </span>
              }
            />
          </FormControl>
        </Grid>
        {errors?.server && (
          <Grid item marginTop={5} className={styles.redText}>
            {errors.server}
          </Grid>
        )}
        <Grid item marginTop={5}>
          <PrimaryButton onClick={submitForm}>Utwórz konto</PrimaryButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegisterPage;
