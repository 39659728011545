import React, {useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {aboutTagImage, vaccinationIdImage} from '../../assets';

import styles from './AboutTag.module.css';
import {PANEL1, PANEL2} from '../../const';

const AboutTag = () => {
  const [expanded, setExpanded] = useState(PANEL1);

  const handleChangePanel2 = (event, isExpanded) => {
    setExpanded(isExpanded ? PANEL2 : null);
  };
  const handleChangePanel1 = (event, isExpanded) => {
    setExpanded(isExpanded ? PANEL1 : null);
  };

  return (
    <div>
      <section>
        <div className={styles.sectionRow}>
          <h1>CZYM SĄ RABIESKI?</h1>
        </div>
      </section>

      <section>
        <div className={styles.section}>
          <div className={styles.aboutTagRow}>
            <div className={styles.sectionColumn}>
              <img src={vaccinationIdImage} alt="" />
            </div>
            <div className={styles.sectionColumn5}>
              <div className={styles.aboutRabieski}>
                Rabieski to innowacyjny system identyfikacji szczepienia, który
                opiera się na prostym rozwiązaniu, jakim jest przywieszany do
                obroży, grawerowany identyfikator. Dane na nim zamieszczone
                pozwalają na przekazanie informacji o znalezionemu zwierzęciu
                lekarzowi weterynarii lub właścicielowi poprzez naszą bazę.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{backgroundColor: '#f8f8f8'}}>
        <div className={styles.section}>
          <div className={styles.sectionColumn}>
            <div className={styles.aboutTagHeaderContainer}>
              <Typography className={styles.aboutTagHeaderText}>
                Informacje na identyfikartorze:
              </Typography>
            </div>

            <ul className={styles.aboutTagDashList}>
              <li>rok szczepienia,rodzaj szczepionki</li>
              <li>nazwa gabinetu, w którym odbyło się szczepienie</li>
              <li>dane kontaktowe gabinetu</li>
              <li>numer identyfikacyjny wprowadzany do ewidencji</li>
            </ul>
          </div>
          <div className={styles.sectionColumn}>
            <img src={aboutTagImage} alt="" />
          </div>
          <div className={styles.sectionColumn}>
            <div>
              <div className={styles.aboutTagNumberingHeader}>
                Numeracja Rabiesek:
              </div>
              <Accordion
                expanded={expanded === PANEL1}
                onChange={handleChangePanel1}
                className={styles.accordion}>
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className={styles.accordionSummary}>
                  <Typography sx={{flexShrink: 0, fontFamily: 'Lato'}}>
                    Klasyczna
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetailsContainer}>
                  <Typography className={styles.accordionDetailsText}>
                    Numeracja zaczynająca się każdego roku od numeru 1. W takim
                    wypadku dotarcie do właściciela odbywa się poprzez kontakt z
                    gabinetem / lecznicą, która prowadzi ewidencję.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === PANEL2}
                onChange={handleChangePanel2}
                className={styles.accordion}>
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  className={styles.accordionSummary}>
                  <Typography sx={{flexShrink: 0, fontFamily: 'Lato'}}>
                    10 cyfrowa
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetailsContainer}>
                  <Typography className={styles.accordionDetailsText}>
                    Umożliwiająca rejestrację w bazie Rabiesek, dzięki czemu
                    istnieje możliwość dotarcia do właściciela zarówno poprzez
                    gabinet jak i bazę danych dostępną w internecie - bez
                    konieczności powiadamiania lekarza weterynarii.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutTag;
