export const firebaseLoginErrors = {
  'auth/user-not-found': 'Wprowadzono niepoprawny adres e-mail lub hasło.',
  'auth/wrong-password': 'Wprowadzono niepoprawny adres e-mail lub hasło.',
  'auth/invalid-email':
    'Wprowadzony adres e-mail jest niepoprawnie sformatowany.',
  'auth/user-disabled': 'Adres e-mail przypisany do konta jest nieaktywny',
};

export const backendErrors = {
  'Vaccination Exists':
    'Podany identyfikator szczepienia jest już obecny w bazie, aby dokończyć rejestrację lub edytować dane prosimy o kontakt z Administatorem biuro@rabieski.pl',
};
