import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import LoginForm from '../../components/LoginForm/LoginForm';
import {HOME_PAGE} from '../../const/routes';
import styles from './LoginPage.module.css';

const LoginPage = () => {
  const userData = useSelector(state => state.auth.userData);

  return userData ? (
    <Navigate to={HOME_PAGE} />
  ) : (
    <div className={styles.container}>
      <LoginForm />
    </div>
  );
};

export default LoginPage;
