import React from 'react';
import {CircularProgress, Grid} from '@mui/material';

const Loader = () => (
  <Grid
    container
    position={'absolute'}
    display={'flex'}
    justifyContent={'center'}
    zIndex={10}>
    <CircularProgress />
  </Grid>
);

export default Loader;
